import React, { useState } from 'react';

import LinkButton from '../../components/buttons/LinkButton';
import { createUser } from '../../util/authHelper';
import ErrorModal from '../../components/modals/ErrorModal';

const NewUser = ({ onChangeType, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function handleSubmit(event) {
    if (password === confirmPassword && email) {
      createUser({ username: email, password })
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage(error.response.data.message);
        });
    }
    event.preventDefault();
  }

  function handleModalClick() {
    setErrorMessage('');
  }

  function handleEmailChange({ target: { value } }) {
    setEmail(value);
  }

  function handlePasswordChange({ target: { value } }) {
    setPassword(value);
  }

  function handleConfirmPasswordChange({ target: { value } }) {
    setConfirmPassword(value);
  }

  return (
    <div>
      <h2>Sign up</h2>

      <form className="pure-form pure-form-stacked" onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          id="email"
          type="email"
          onChange={handleEmailChange}
        />
        <label htmlFor="password">Password</label>
        <input
          name="password"
          id="password"
          type="password"
          onChange={handlePasswordChange}
        />
        <label htmlFor="confirmPassword">Confirm password</label>
        <input
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          onChange={handleConfirmPasswordChange}
        />
        <input type="submit" className="pure-button pure-button-primary" />
      </form>

      <div>
        Already have an account?{' '}
        <LinkButton onClick={onChangeType}>Sign in here!</LinkButton>
      </div>

      <ErrorModal show={errorMessage} onClick={handleModalClick}>
        {errorMessage}
      </ErrorModal>
    </div>
  );
};

export default NewUser;
