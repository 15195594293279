import React, { useState, Fragment } from 'react';
import { recover } from '../../util/authHelper';
import LinkButton from '../../components/buttons/LinkButton';

const RecoverUser = ({ onChangeType }) => {
  const [email, setEmail] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  function handleSubmit(event) {
    recover(email);
    setShowSuccess(true);
    event.preventDefault();
  }

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function getBody() {
    if (showSuccess) {
      return (
        <Fragment>
          <h3>Success!</h3>
          <div>
            If an account exists with that email, a recovery link will be sent
            to it.
          </div>
        </Fragment>
      );
    } else {
      return (
        <form className="pure-form pure-form-stacked" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input type="email" onChange={handleChange} />
          <input
            type="submit"
            className="pure-button pure-button-primary"
            value="Submit"
          />
        </form>
      );
    }
  }

  return (
    <div>
      <h2>Account Recovery</h2>

      {getBody()}

      <LinkButton name="existing" onClick={onChangeType}>
        Return to login.
      </LinkButton>
    </div>
  );
};

export default RecoverUser;
