import React from 'react';

const PrimaryButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`pure-button pure-button-primary${
        className ? ` ${className}` : ''
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
