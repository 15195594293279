import React, { useState } from 'react';

import LinkButton from '../../components/buttons/LinkButton';
import { authenticate } from '../../util/authHelper';

const ExistingUser = ({ onChangeType, onSuccess }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  function handleSubmit(event) {
    authenticate({ username, password }).then(() => {
      onSuccess();
    });
    event.preventDefault();
  }

  function handleInputChange({ target: { name, value } }) {
    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        console.error('state was not updated properly');
    }
  }

  return (
    <div>
      <h2>Login</h2>

      <form className="pure-form pure-form-stacked" onSubmit={handleSubmit}>
        <label htmlFor="username">Email</label>
        <input
          type="email"
          id="username"
          name="username"
          onChange={handleInputChange}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          onChange={handleInputChange}
        />
        <input
          type="submit"
          className="pure-button pure-button-primary"
          value={'Login'}
        />
      </form>

      <div>
        Need an account?{' '}
        <LinkButton name="newAccount" onClick={onChangeType}>
          Sign up here!
        </LinkButton>
      </div>
      <div>
        Forgot your username or password?{' '}
        <LinkButton name="recoverAccount" onClick={onChangeType}>
          Recover your account.
        </LinkButton>
      </div>
    </div>
  );
};

export default ExistingUser;
