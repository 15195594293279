import React, { Fragment, useState } from 'react';
import ErrorModal from '../../components/modals/ErrorModal';

const Home = () => {
  const [show, setShow] = useState(false);

  function handleClick() {
    setShow(!show);
  }

  return (
    <Fragment>
      <div>Home</div>
      <button onClick={handleClick}>Show Modal</button>

      <ErrorModal show={show} onClick={handleClick}>
        Hello world
      </ErrorModal>
    </Fragment>
  );
};

export default Home;
