const axios = require('axios');
const { getAuthToken } = require('../authHelper');

function trimAmazonRemaps(header) {
  if (header.includes('x-amzn-remapped-')) {
    return header.substring(16);
  }
  return header;
}

function generalize(response) {
  Object.keys(response.headers).forEach((header) => {
    Object.defineProperty(
      response.headers,
      trimAmazonRemaps(header),
      Object.getOwnPropertyDescriptor(response.headers, header)
    );
  });
  return response;
}

export const sendRequest = ({ headers, ...args }) => {
  return sendUnauthenticatedRequest({
    headers: {
      Authentication: `Bearer ${getAuthToken()}`,
      ...headers,
    },
    ...args,
  });
};

export const sendUnauthenticatedRequest = (args) => {
  return axios({
    baseURL: 'https://api.forgeofmars.com',
    timeout: 10000,
    responseType: 'json',
    ...args,
  }).then((response) => {
    return generalize(response);
  });
};
