import React from 'react';
import './errorButton.scss';

const ErrorButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`pure-button error-button${className ? ` ${className}` : ''}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ErrorButton;
