import React from 'react';
import './styles.css';

const LinkButton = ({ className, ...props }) => {
  return (
    <button
      className={`component-link-button${className ? ` ${className}` : ''}`}
      {...props}
    />
  );
};

export default LinkButton;
