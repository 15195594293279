import React from 'react';
import './basicModal.scss';

const Modal = ({ show, children, className }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal-backdrop">
      <div className={`modal${className ? ` ${className}` : ''}`}>
        <div className="modal-container">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
