import React, { Fragment, useState } from 'react';
import Modal from './BasicModal';
import './errorModal.scss';
import ErrorButton from '../buttons/ErrorButton';
import PrimaryButton from '../buttons/PrimaryButton';

const ErrorModal = ({ children, className, onClick, ...props }) => {
  const [errorIsReported, setErrorIsReported] = useState(false);

  function handleReport() {
    setErrorIsReported(true);
    setTimeout(() => {
      onClick();
      setErrorIsReported(false);
    }, 2000);
  }

  const errorMessage = (
    <Fragment>
      <h1>Well that is embarrassing!</h1>
      {children}
      <div className="footer">
        <ErrorButton onClick={handleReport}>Report it</ErrorButton>
        <PrimaryButton onClick={onClick}>OK</PrimaryButton>
      </div>
    </Fragment>
  );

  const reportedMessage = (
    <Fragment>
      <h1>Error report sent</h1>
    </Fragment>
  );

  return (
    <Modal
      className={`errorModal${className ? ` ${className}` : ''}`}
      {...props}
    >
      {errorIsReported ? reportedMessage : errorMessage}
    </Modal>
  );
};

export default ErrorModal;
