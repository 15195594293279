import React from 'react';
import { Route } from 'react-router-dom';

const DoesNotExist = () => {
  return (
    <Route path="*">
      <h2>The resource you are looking for does not exist.</h2>
    </Route>
  );
};

export default DoesNotExist;
